.header-review {
    width: 1440px;
    min-width: 1440px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    margin: 0 auto 40px auto;
    padding: 50px 150px 0 150px;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
        padding: 50px 0 0 28px;
    }
    position: relative;
    .logo {
        margin-bottom: 10px;
    }
    p {
        font-family: $helveticaMedium;
        font-weight: normal;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.25;
        text-align: left;
        padding: 0;
        margin: 0;
        color: $orange;
    }
}

.review {
    width: 100%;
    grid-template-columns: 686px 1fr;
    grid-template-rows: auto;
    display: grid;
    grid-gap: 0 50px;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 40px;
    .review__form {
        grid-column: 1 / span 1;
        .review__form__row {
            width: 100%;
            border-bottom: 1px solid $grey;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            grid-gap: 0 45px;
            display: grid;
            align-items: flex-start;
            justify-content: space-between;
            padding-bottom: 14px;
            margin-bottom: 4px;
            .review__form__col {
                width: 100%;
                justify-self: start;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                .flex-row {
                    width: 100%;
                    justify-content: space-between !important;
                }
                .ico-correct {
                    width: 18px;
                    height: 18px;
                    background: url('../../image/icons/review-correct.svg') center center no-repeat;
                    position: relative;
                }
                .ico-error {
                    width: 18px;
                    height: 18px;
                    background: url('../../image/icons/review-error.svg') center center no-repeat;
                    position: relative;
                }
                input:read-only {
                    color: $blue-grey !important;
                    background: $grey !important;
                    &::placeholder {
                        color: $blue-grey !important;
                    }
                }
            }
        }
        h1 {
            font-family: $helveticaBold;
            font-weight: normal;
            font-size: 24px;
            line-height: normal;
            text-align: left;
            color: $blue-med;
            padding: 0;
            margin: 0 0 16px 0;
        }
        p {
            width: 50%;
            font-family: $helveticaRegular;
            font-size: 16px;
            line-height: 1.5;
            margin-top: 0;
            margin-bottom: 18px;
            padding: 0;
            color: $blue-grey;
            display: inline-block;
            &.error {
                width: 100%;
            }
            a {
                font-family: $helveticaBold;
                font-weight: normal;
                color: $blue-dark;
                text-decoration: none;
                padding-right: 35px;
                background: url('../../image/icons/arrow-right.svg') 95% center no-repeat;
                background-size: auto 14px;
                transition: $transition;
                &:hover {
                    background-position: 100% center;
                }
            }
        }
    }
    .review__cta {
        grid-column: 2 / span 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        .button-primary {
            min-width: 169px;
        }
    }
}