.agenda__detail__form {
    width: 100%;
    max-height: 100vh;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    overflow-y: auto;

    padding-right: 32px;
    padding-left: 32px;

    form {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-gap: 24px 30px;
        display: grid;

        position: relative;

        padding-bottom: 290px;

        p.error{
            margin-top: 7px !important;
        }

        input {
            margin-bottom: 0 !important;
        }

        label {
            margin-top: 0;
        }

        .form__full {
            grid-column: 1 / span 2;
        }

        .form__mid-l {
            grid-column: 1 / span 1;
        }

        .form__mid-r {
            grid-column: 2 / span 1;
        }

        .date-container {
            select {
                width: 100% !important;
            }

            .css-2b097c-container {
                margin-bottom: 24px;
            }

            .css-1hwfws3 {
                height: 50px;

                padding: 0 0 0 12px;
                margin: 0;
            }

            .aiga-select-container, .aiga-select__control {
                width: 100%;
                height: 50px;

                font-family: $helveticaRegular;
                font-size: 16px;
                font-weight: normal;
                line-height: normal;
                text-align: center;

                color: $blue-med;

                background: $white;

                border: 1px solid $white-dark;
                border-radius: 4px;

                padding: 0;
                margin: 0;

                display: flex;
                align-items: center;
                justify-content: flex-start;

                position: relative;

                box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.02);

                cursor: pointer;

                &:focus, .aiga-select__control--is-focused {
                    box-shadow: 0 0 10px rgba(47, 48, 71, .5) !important;
                }


                .aiga-select__placeholder {
                    font-family: $helveticaRegular;
                    font-size: 16px;
                    font-weight: normal;
                    line-height: normal;
                    text-align: center;

                    color: $light-periwinkle;
                }

                .css-b8ldur-Input {
                    height: 50px;

                    margin: 0;
                    padding: 0;

                    input {
                        box-shadow: none !important;
                        outline: none !important;

                        &:focus {
                            box-shadow: none !important;
                            outline: none !important;
                        }
                    }
                }

                span {
                    display: none;
                }

                &:disabled {
                    background: $grey;
                }

                &:focus {
                    box-shadow: 0 0 10px rgba(47, 48, 71, .5);
                }

                .aiga-select__caret {
                    width: 30px;
                    height: 100%;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .aiga-select__menu {
                background: $white;

                border: 1px solid #f3f3f3;
                border-radius: 4px;

                padding: 14px;

                box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);

                display: block;

                .aiga-select__menu-list {
                    width: 100%;

                    margin: 0;
                    padding: 0;

                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;

                    list-style: none;

                    .aiga-select__option {
                        width: 100%;
                        height: auto;

                        margin: 0;
                        padding: 14px;

                        font-family: $helveticaRegular;
                        font-size: 16px;
                        line-height: 1;
                        text-decoration: none;
                        color: $blue-med;
                        text-align: left;

                        border: none;
                        border-radius: 4px;

                        position: relative;
                        display: block;

                        transition: $transition;

                        cursor: pointer;

                        &:hover{
                            color: $blue-med !important;
                            background: $white-dark !important;
                        }

                        &.aiga-select__option--is-selected{
                            color: $white !important;
                            background: $main-gradient !important;

                            transition: $transition;

                            &:hover{
                                color: $white !important;
                                background: $main-gradient !important;
                            }
                        }

                        &.aiga-select__option--is-focused{
                            color: $blue-med;
                            background: $light-periwinkle;

                            transition: $transition;
                        }

                        &.aiga-select__option--is-disabled{
                            color: $blue-med;

                            transition: $transition;
                        }
                    }
                }
            }
        }

        .switch-container {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: space-between;

            label {
                margin: 0;
                padding: 0;
            }

            p {
                font-family: $helveticaRegular;
                font-size: 16px;
                line-height: 1;
                text-align: left;

                color: $blue-med;

                margin: 0;
                padding: 0;

                position: relative;
                display: block;
            }
        }

        .form__color {
            width: 100%;
            height: 2px;

            position: relative;
            display: block;

            border-radius: 1px;

            background: $light-grey;

            &.app {
                background: $green;
            }

            &.udi {
                background: $brown;
            }

            &.ade {
                background: $violet;
            }
        }

        .button-secondary {
            width: 100% !important;

            font-family: $helveticaRegular !important;
            font-size: 16px !important;

            padding-left: 12px !important;
            padding-right: 20px !important;
        }

    }

    .cta-container {
        width: 436px;
        height: 66px;

        background: rgb(252, 252, 252);
        box-shadow: 0 -1px 0 0 #F6F6F6;

        position: fixed;
        bottom: 0;
        right: 0;

        display: flex;
        align-items: center;
        justify-content: flex-end;

        padding: 0 40px;

        z-index: 20;

        transition: all .5s ease-in-out;
    }

}