.modifyVerbal{
    width: 100%;
    height: auto;

    grid-template-columns: 700px 1fr;
    grid-template-rows: auto auto;
    display: grid;
    grid-gap: 216px 100px;

    padding-left: 40px;
    padding-right: 40px;

    position: relative;

    .textarea{
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;

        position: relative;
        display: block;

        p.original{
            font-family: $helveticaRegular;
            font-size: 16px;
            line-height: 20px;
            text-align: left;
            color: $blue-med;

            margin: 0 0 16px 0;
            padding: 0;
        }

        textarea{
            width: 100%;
            height: 150px;

            font-family: $helveticaRegular;
            font-size: 16px;
            line-height: 20px;
            text-align: left;
            color: $blue-med;

            background: $grey;
            border: none;

            padding: 18px 16px;
            margin: 0;

            resize: none;
        }
    }

    .cta-container{
        grid-column: 2 / span 1;
        grid-row: 2 /span 1;

        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .cancel{
        width: auto;
        height: auto;

        font-family: $helveticaMedium;
        font-size: 16px;
        line-height: 1.5;
        text-align: center;

        padding: 0;
        margin: 0 32px 0 0;

        color: $blue-med;

        background: transparent;
        border: none;

        transition: $transition;

        &:hover{
            color: $blue;
        }
    }
}