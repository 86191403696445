        .profile {
            width: 100%;
            height: auto;
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
                grid-template-columns: 50px auto;
                .image {
                    display: none !important;
                    img {
                        display: none;
                    }
                }
            }
            grid-template-columns: 427px 686px;
            grid-template-rows: auto;
            display: grid;
            grid-gap: 0 75px;
            margin-top: 70px;
            padding: 0 40px;
            position: relative;
            .image {
                grid-column: 1 / span 1;
                grid-row: 1 / span 1;
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                img {
                    width: 335px;
                    height: auto;
                    position: relative;
                    display: block;
                }
            }
            .col {
                grid-column: 2 / span 1;
                grid-row: 1 / span 1;
                .row {
                    grid-column: 1 /span 1;
                    grid-template-columns: 327px 327px;
                    grid-template-rows: auto;
                    display: grid;
                    grid-gap: 0 32px;
                    padding-bottom: 32px;
                    border-bottom: 1px solid #f6f6f6;
                    .cell {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        flex-wrap: wrap;
                        &:nth-of-type(odd) {
                            grid-column: 1 / span 1;
                        }
                        &:nth-of-type(even) {
                            grid-column: 2 /span 1;
                        }
                        .password-container {
                            .unhide {
                                top: 64px;
                            }
                        }
                        input {
                            margin-bottom: 0;
                            &:read-only,
                            &:disabled {
                                color: $blue-grey;
                                background: $grey;
                                -webkit-text-fill-color: $blue-grey;
                                opacity: 1 !important;
                            }
                        }
                        label.full {
                            width: 100%;
                        }
                        a {
                            width: 100%;
                            font-family: $helveticaRegular;
                            font-size: 16px;
                            line-height: 20px;
                            text-align: left;
                            color: $blue-dark;
                            text-decoration: none;
                            padding: 0;
                            margin: 0 0 28px 0;
                            position: relative;
                            display: block;
                            background: url('../../image/icons/arrow-right.svg') 97% center no-repeat;
                            background-size: 17px auto;
                            transition: $transition;
                            &:first-of-type {
                                margin-top: 16px;
                            }
                            &:last-of-type {
                                margin-bottom: 0;
                            }
                            &:hover {
                                background-position: 100% center;
                            }
                        }
                        .cancel {
                            width: auto;
                            height: 50px;
                            font-family: $helveticaMedium;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 1.5;
                            text-align: center;
                            padding: 0;
                            margin: 90px 0 0 0;
                            color: $blue-med;
                            background: transparent;
                            border: none;
                            transition: $transition;
                            &:hover {
                                color: $blue;
                            }
                        }
                        .button-primary {
                            margin: 90px 0 0 0;
                            align-self: flex-end;
                        }
                    }
                }
                p {
                    font-family: $helveticaBold;
                    font-weight: normal;
                    font-size: 20px;
                    line-height: 24px;
                    text-align: left;
                    margin: 0 0 24px 0;
                    padding: 0;
                    position: relative;
                    display: block;
                }
            }
        }