.agenda {
    width: 100%;
    //max-height: 1304px;
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 auto;
}

.load-more {
    margin-bottom: 20px;
}

.agenda__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 40px 36px 40px;
    .logo {
        width: 92px;
        height: auto;
        min-height: 50px;
        position: relative;
        display: block;
        img {
            width: 100%;
            height: auto;
            position: relative;
            display: block;
        }
    }
    .cta-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        .cta-profile {
            width: 25px;
            height: 25px;
            margin-left: 24px;
            border-radius: 50%;
            position: relative;
            display: block;
            background: url('../../image/icons/profile.svg') center center no-repeat transparent;
            background-size: cover;
            cursor: pointer;
            transition: $transition;
            
        }
        .cta-notifications {
            width: 21px;
            height: 25px;
            margin-right: 24px;
            position: relative;
            display: block;
            background: url('../../image/icons/icon_notification.svg') center center no-repeat transparent;
            background-size: cover;
            transition: $transition;
            cursor: pointer;
            
        }
        .notification_badge {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 21px;
            height: 21px;
            background-color: #D93C28;
            position: absolute;
            border-radius: 10.5px !important;
            top: -10px;
            left: -10px;
        }
        .notification_text {
            color: white;
            font-size: 14px;
            font-family: $sfMedium;
        }
        .container {
            display: flex;
            flex-direction: column;
            margin-top: 4px;
            margin-left: 16px;
            margin-right: 16px;
            .sub_container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
            .divider {
                background-color: #F6F6F6;
                height: 1px;
                margin-left: -10px;
                margin-right: -10px;
            }
        }
        .notification_item_container {
            display: flex;
            flex-direction: column;
            margin-right: 10px;
            .date {
                margin-top: 16px;
                font-size: 12px;
                color: #82849D;
                margin-bottom: 6px;
                font-family: $sfRegular;
            }
            .unread {
                font-size: 16px;
                color: #2F3047;
                margin-bottom: 6px;
                font-family: $sfBold;
                font-weight: 700;
            }
            .read {
                font-size: 16px;
                color: #2F3047;
                margin-bottom: 6px;
                font-family: $sfRegular;
                font-weight: 400;
            }
            .subtitle {
                font-size: 14px;
                color: #82849D;
                font-family: $sfRegular;
                margin-bottom: 10px;
            }
        }
        .notification_empty_state_container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 375px;
            .notification_loading {
                justify-content: center;
                align-self: center;
                width: 100%;
                height: 100%;
                display: flex;
                top: 0;
                left: 0;
                background: url('../../image/icons/loading.svg') center center no-repeat;
                animation-name: loading;
                animation-duration: 2s;
                animation-iteration-count: infinite;
            }
            .empty_state_text {
                font-size: 14px;
                font-family: $sfRegular;
                color: #82849D;
            }
        }
        .menu-notifications-wrapper {
            width: 400px;
            height: 398px;
            overflow: scroll;
            padding: 12px 8px;
        }
        .menu-notifications {
            width: 400px;
            height: 400px;
            background: $white;
            border: 1px solid $white-dark;
            border-radius: 4px;
            box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.05);
            position: absolute;
            top: 70px;
            right: -10px;
            z-index: 10;
            &:after,&:before {
                content: '';
                display: block;
                position: absolute;
                left: 50%;
                width: 0;
                height: 0;
                border-style: solid;
            }
            &:after {
                top: -12px;
                border-color: transparent transparent rgb(255, 255, 255) transparent;
                border-width: 6px;
            }
            /* this border color controls the outside, thin border */
            &:before {
                top: -13px;
                border-color: transparent transparent $white-dark transparent;
                border-width: 6px;
            }
            ul {
                width: 100%;
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                list-style: none;
                li {
                    width: 100%;
                    text-align: left;
                    margin: 0;
                    padding: 0;
                    position: relative;
                    display: block;
                    float: left;
                    a {
                        width: 100%;
                        height: auto;
                        margin: 0;
                        font-family: $helveticaMedium;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 1;
                        text-decoration: none;
                        border: none;
                        border-radius: 4px;
                        position: relative;
                        display: block;
                        transition: $transition;
                    }
                    &:first-of-type {
                        a {
                            color: $blue;
                            background: $white-dark;
                            padding: 8px;
                            &:hover {
                                background: transparent;
                            }
                        }
                    }
                    &:last-of-type {
                        a {
                            color: $blue-med;
                            background: transparent;
                            padding: 8px 8px 0 8px;
                            &:hover {
                                color: $blue;
                                background: transparent;
                            }
                        }
                    }
                }
            }
        }

        .menu-notifications:before {
            content: '';
                display: block;
                position: absolute;
                left: 50%;
                width: 0;
                height: 0;
                border-style: solid;
          }

        .menu-notifications.menu-notifications-secondary {
            top: 58px;
            &:after,&:before {
                content: '';
                display: block;
                position: absolute;
                left: 75%;
                width: 0;
                height: 0;
                border-style: solid;
            }
        }

        .menu-profile {
            width: 160px;
            height: auto;
            background: $white;
            border: 1px solid $white-dark;
            border-radius: 4px;
            padding: 14px 8px;
            box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.05);
            position: absolute;
            top: 70px;
            right: -10px;
            z-index: 10;
            &:after,
            &:before {
                content: '';
                display: block;
                position: absolute;
                right: 15px;
                width: 0;
                height: 0;
                border-style: solid;
            }
            &:after {
                top: -12px;
                border-color: transparent transparent rgb(255, 255, 255) transparent;
                border-width: 6px;
            }
            /* this border color controls the outside, thin border */
            &:before {
                top: -13px;
                border-color: transparent transparent $white-dark transparent;
                border-width: 6px;
            }
            ul {
                width: 100%;
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                list-style: none;
                li {
                    width: 100%;
                    text-align: left;
                    margin: 0;
                    padding: 0;
                    position: relative;
                    display: block;
                    float: left;
                    a {
                        width: 100%;
                        height: auto;
                        margin: 0;
                        font-family: $helveticaMedium;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 1;
                        text-decoration: none;
                        border: none;
                        border-radius: 4px;
                        position: relative;
                        display: block;
                        transition: $transition;
                    }
                    &:first-of-type {
                        a {
                            color: $blue;
                            background: $white-dark;
                            padding: 8px;
                            &:hover {
                                background: transparent;
                            }
                        }
                    }
                    &:last-of-type {
                        a {
                            color: $blue-med;
                            background: transparent;
                            padding: 8px 8px 0 8px;
                            &:hover {
                                color: $blue;
                                background: transparent;
                            }
                        }
                    }
                }
            }
        }

        .menu-profile.menu-profile-secondary {
            top: 58px;
            
        }
    }
}