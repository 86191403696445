.addVerbal{
    width: 436px;
    height: auto;

    background: rgb(252, 252, 252);
    border: 1px solid $white-dark;

    border-radius: 20px;

    margin: 120px auto 0 auto;
    padding: 32px;

    position: relative;
    display: block;

    .previewVerbal{
        .button-primary{
            align-self: center;
        }
    }
}