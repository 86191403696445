button {
    border: none;

    box-shadow: none;

    cursor: pointer;
}

button.button-placeholder-submit {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
}

.button-main {
    width: 100%;
    height: 50px;

    font-family: $helveticaBold;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;

    border-radius: 8px;

    background: $white;
    border: 1px solid transparent;

    display: flex;
    align-items: center;
    justify-content: center;

    color: $blue-dark;

    transition: $transition;

    padding: 0;
    margin: 0;

    &:hover {
        border: 1px solid $blue-grey;
    }

    &:focus {
        border: 1px solid $blue-light;
    }
}

.button-login {
    width: 100%;
    height: auto;

    font-family: $helveticaBold;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;

    border-radius: 8px;

    background: transparent;

    display: flex;
    align-items: center;
    justify-content: center;

    color: $light-periwinkle;

    transition: $transition;

    margin: 15px 0 0 0;
    padding: 0;

    &:hover {
        color: $white;

        background-color: transparent;
    }
}

.button-primary {
    height: 50px;

    font-family: $helveticaBold;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;

    border-radius: 4px;
    border: none;

    background: linear-gradient(90deg, rgba(57, 77, 125, 1) 0%, rgba(30, 50, 97, 1) 100%);

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    color: $white;

    padding: 0 32px;
    margin: 0;

    cursor: pointer;

    transition: $transition;

    span {
        z-index: 10;
    }

    &:after {
        width: 100%;
        height: 100%;

        content: '';

        position: absolute;
        top: 0;
        left: 0;

        background: #000000;

        opacity: 0;

        transition: $transition;

        z-index: 0;
    }

    &:hover {
        color: $white;

        background: linear-gradient(90deg, rgba(57, 77, 125, 1) 0%, rgba(30, 50, 97, 1) 100%);

        &:after {
            opacity: .3;
        }
    }

    &:disabled, &.disabled {
        color: $light-periwinkle;

        background: $grey;

        cursor: not-allowed;

        &:after {
            display: none;
        }

        &:hover {
            color: $light-periwinkle;

            background: $grey;
        }
    }

    &:focus {
        border: 1px solid $white;
    }
}

.button-secondary {
    height: 50px;

    font-family: $helveticaMedium;
    font-weight: normal;
    font-size: 16px;
    line-height: 1;
    text-align: center;
    text-decoration: none;

    border-radius: 4px;
    border: solid 1px rgb(243, 243, 243);

    background: $white;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    color: $blue;

    padding: 0 32px;
    margin: 0;

    cursor: pointer;

    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);

    transition: $transition;


    &:hover {
        border-color: $blue-grey;
    }

    &:focus {
        border-color: $blue-dark;
    }

    &:disabled, &.disabled {
        opacity: .5;

        cursor: not-allowed;

        &:hover {
            opacity: .5;
            background: $white;
            color: $blue;
        }
    }

    &.icon {
        span.icon {
            width: 16px;
            height: 16px;

            position: relative;
            display: block;

            margin-right: 12px;

            img {
                width: 100%;
                height: auto;

                position: relative;
                display: block;
            }
        }

        span.txt {
            padding-top: 1px;
            position: relative;
            display: block;
        }
    }

    &.icon-right {
        span.icon {
            width: 16px;
            height: 16px;

            position: relative;
            display: block;

            margin: 0 0 0 auto;

            img {
                width: 100%;
                height: auto;

                position: relative;
                display: block;
            }
        }
    }
}

.button-loading {
    width: 100%;
    height: 100%;
    //width: 30px;
    //height: 30px;

    display: block;
    position: absolute;

    //top: calc(50% - 15px);
    //left: calc(50% - 15px);

    top: 0;
    left: 0;

    background: url('../../image/icons/loading.svg') center center no-repeat;

    animation-name: loading;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes loading {
    0% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg)
    }
}