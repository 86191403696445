.loading-wrapper {
    width: 100%;
    min-height: 100vh;

    background: $white;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    padding-top: 100px;
    padding-left: 30px;
    padding-right: 30px;

    .logo-container {
        width: 100%;
        height: auto;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        position: relative;

        margin-bottom: 48px;

        p {
            width: 100%;

            font-family: $helveticaMedium;
            font-size: 16px;
            font-weight: normal;
            line-height: 1.25;
            text-align: center;

            padding: 0;
            margin: 0;

            color: $orange;
        }
    }

    h1 {
        width: 100%;

        font-family: $helveticaBold;
        font-weight: normal;
        font-size: 28px;
        line-height: normal;
        text-align: center;

        color: $blue-med;

        padding: 0;
        margin: 0 0 50px 0;
    }

    .loader {
        width: 50px;
        height: 50px;

        position: relative;
        display: block;

        background: url('../../image/icons/loading.svg') center center no-repeat;

        animation-name: loading;
        animation-duration: 2s;
        animation-iteration-count: infinite;
    }
}