.agenda__agenda {
    width: 428px;
    height: calc(100% - 118px);
    //min-height: 100vh;

    background: $white;
    border-top: 1px solid $white-dark;
    border-right: 1px solid $white-dark;
    border-left: none;
    border-bottom: none;

    border-radius: 0 20px 0 0;

    padding: 30px 14px 0 64px;

    box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.03);

    ////position: relative;

    position: fixed;
    left: 0;
    top: 118px;


    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;

    .agenda__agenda__content {
        width: 100%;
        height: auto;

        position: relative;

        overflow-y: auto;

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;

        -ms-overflow-style: none; // IE 10+
        scrollbar-width: none; // Firefox

        &::-webkit-scrollbar {
            display: none; // Safari and Chrome
        }
    }

    h1 {
        font-family: $helveticaBold;
        font-weight: normal;
        font-size: 22px;
        line-height: normal;
        text-align: left;
        color: $blue;

        padding: 0;
        margin-top: 0;
        margin-bottom: 42px;
    }

    .agenda__calendar {
        width: 100%;
        height: auto;

        margin-bottom: 40px;
    }

    a.cta-verbale {
        width: 100%;
        height: auto;

        font-family: $helveticaBold;
        font-weight: normal;
        font-size: 22px;
        line-height: normal;
        color: $blue-med;
        text-decoration: none;

        padding: 0;
        margin-bottom: 34px;

        background: url('../../image/icons/arrow-light-right.svg') right center no-repeat;
        background-size: 10px auto;

        position: relative;
        display: block;

        transition: $transition;

        &:hover, &.active {
            color: $blue;
        }

        &.active {
            background: url('../../image/icons/arrow-dark-right.svg') right center no-repeat;

            pointer-events: none;
        }
    }

    .agenda__service {
        width: 100%;
        height: auto;

        border-bottom: 1px solid $light-grey;

        position: relative;

        margin-bottom: 24px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &:last-of-type {
            border: none;
            margin-bottom: 0;
        }

        a {
            height: auto;

            font-family: $helveticaMedium;
            font-size: 18px;
            line-height: 1.5;
            text-align: left;
            color: $blue-grey;
            text-decoration: none;

            background: $white;

            position: relative;
            display: block;

            padding-left: 10px;
            padding-right: 10px;
            padding-top: 5px;
            padding-bottom: 5px;

            margin-bottom: 20px;

            border-radius: 4px;

            transition: $transition;

            span {
                border-radius: 4px;

                position: relative;
                display: block;

                z-index: 2;
            }

            &::after {
                width: 100%;
                height: 100%;

                content: '';

                background: $main-gradient;

                opacity: 0;

                transition: $transition;

                position: absolute;
                top: 0;
                left: 0;

                border-radius: 4px;


                z-index: 1;
            }

            &:hover {
                color: $white;

                &::after {
                    opacity: 1;
                }
            }

            &.with-icon{
                display: flex;
                align-items: center;
                flex-wrap: nowrap;

                img{
                    width: 28px;
                    height: auto;

                    position: relative;
                    display: block;

                    margin-right: 16px;

                    z-index: 2;
                }
            }
        }
    }
}