/*---------- Font base -----------*/

$sans: Arial, sans-serif;
$serif: Times, serif;


/*---------- Font FAMILY -----------*/

$sfRegular: 'SF UI Display Regular', $sans;
$sfMedium: 'SF UI Display Medium', $sans;
$sfBold: 'SF UI Display Bold', $sans;

$helveticaRegular: 'Helvetica Roman', $sans;
$helveticaRegularItalic: 'Helvetica Italic', $sans;
$helveticaMedium: 'Helvetica Medium', $sans;
$helveticaBold: 'Helvetica Bold', $sans;


/*---------- Colors -----------*/

$white: rgb(255, 255, 255);
$white-dark: rgb(243, 243, 243);
$light-periwinkle: rgba(218, 218, 230, 1);

$light-grey: rgb(234, 233, 242);
$grey: rgb(247, 248, 255);


$blue: rgb(60, 100, 194);
$blue-grey: rgba(130, 132, 157, 1);
$blue-light: rgb(57, 77, 125);
$blue-med: rgb(47, 48, 71);
$blue-dark: rgb(30, 50, 97);

$red: rgb(243, 126, 111);
$red10: rgba(243, 126, 111, .1);
$green: rgb(32, 138, 134);
$green10: rgba(32, 138, 134, .1);
$violet: rgb(156, 107, 243);
$violet10: rgba(156, 107, 243, .1);
$brown: rgb(160, 120, 85);
$brown10: rgba(160, 120, 85, .1);
$orange: rgb(255, 183, 96);

$main-gradient: linear-gradient(145deg, rgba(57,77,125,1) 30%, rgba(30,50,97,1) 100%);


/*---------- Utils -----------*/
$transition: all .3s ease-in-out;
