.verbalRight {
    .close-right {
        margin-bottom: 20px;
    }
    width: 436px;
    height: auto;
    background: rgb(252, 252, 252);
    border-top: 1px solid $white-dark;
    border-right: none;
    border-left: 1px solid $white-dark;
    border-bottom: 1px solid $white-dark;
    border-radius: 20px 0 0 20px;
    margin: 0;
    padding: 24px;
    box-shadow: -5px 0 10px 0 rgba(0, 0, 0, 0.03);
    position: fixed;
    top: 118px;
    right: 0;
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    // justify-content: flex-start;
    // flex-wrap: wrap;
    transition: all .5s ease-in-out;
    &.closed {
        right: -100%;
    }
}