.agenda__detail__detail {
    width: 100%;
    max-height: 100vh;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    overflow-y: auto;

    padding-right: 32px;
    padding-left: 32px;

    .title-container {
        width: 100%;

        grid-template-columns: 20px 1fr;
        grid-template-rows: auto auto auto;
        grid-gap: 12px 12px;
        display: grid;

        margin-bottom: 40px;

        .check-container {
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;

            height: 20px;

            position: relative;
            display: block;

            .check {
                width: 20px;
                height: 20px;

                border-radius: 5px;
                background: transparent;

                position: relative;
                display: block;

                transition: $transition;

                cursor: pointer;

                &.checked {
                    &:before {
                        content: '';
                        width: 13px;
                        height: 13px;

                        background: url('../../../image/icons/checkmark-white.svg') center center no-repeat;

                        position: absolute;
                        top: 50%;
                        left: 50%;

                        display: block;

                        transform: translate(-50%, -50%);
                    }
                }
            }
        }

        .postponed {
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;

            height: 20px;

            position: relative;
            display: block;

            img {
                height: 20px;
                width: 20px;
            }
        }

        p.title {
            grid-column: 2 / span 1;
            grid-row: 1 / span 1;

            font-family: $helveticaBold;
            font-weight: normal;
            font-size: 20px;
            line-height: 1;
            text-align: left;

            color: $blue-med;

            margin: 0;
            padding: 0;

            position: relative;
            display: block;
        }

        p.time {
            grid-column: 2 / span 1;
            grid-row: 2 / span 1;

            font-family: $helveticaRegular;
            font-size: 16px;
            line-height: 1.3;
            text-align: left;
            color: $blue-grey;

            position: relative;
            display: inline-block;

            padding: 0;
            margin: 0;

            span {
                width: 14px;
                height: auto;

                position: relative;
                display: inline-block;

                margin: 0 8px;

                img {
                    max-width: 100%;
                    height: auto;

                    position: relative;
                    display: block;
                }
            }
        }

        p.date {
            grid-column: 2 / span 1;
            grid-row: 3 / span 1;

            font-family: $helveticaRegular;
            font-size: 16px;
            line-height: 1.3;
            text-align: left;
            color: $blue-grey;
            text-transform: capitalize;

            position: relative;
            display: inline-block;

            padding: 0;
            margin: 0;

            span {
                font-family: $helveticaMedium;

                position: relative;
                display: inline-block;

                margin: 0 0 0 16px;
            }
        }

        &.app {
            .check-container {
                .check {
                    border: 2px solid $green;

                    &.checked {
                        background: $green;
                    }
                }
            }

            p.date {
                span {
                    color: $green;
                }
            }
        }

        &.ade {
            .check-container {
                .check {
                    border: 2px solid $violet;

                    &.checked {
                        background: $violet;
                    }
                }
            }

            p.date {
                span {
                    color: $violet;
                }
            }
        }

        &.udi {
            .check-container {
                .check {
                    border: 2px solid $brown;

                    &.checked {
                        background: $brown;
                    }
                }
            }

            p.date {
                span {
                    color: $brown;
                }
            }
        }
    }

    .content {
        width: 100%;

        grid-template-columns: 24px 1fr;
        grid-template-rows: auto auto;
        grid-gap: 10px 8px;
        display: grid;

        margin-bottom: 28px;

        .icon {
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;

            height: auto;

            display: flex;
            align-items: center;
            flex-direction: column;

            img {
                height: 20px;
                width: auto;

                position: relative;
                display: block;
            }
        }

        .title {
            grid-column: 2 / span 1;
            grid-row: 1 / span 1;

            display: flex;
            align-items: center;

            p {
                font-family: $helveticaRegular;
                font-size: 14px;
                line-height: 1.2;
                text-align: left;

                color: $blue-grey;

                padding: 0;
                margin: 0;

                position: relative;
                display: block;
            }
        }

        .copy {
            grid-column: 2 / span 1;
            grid-row: 2 / span 1;

            p {
                font-family: $helveticaRegular;
                font-size: 16px;
                line-height: 1.2;
                text-align: left;

                color: $blue-med;

                padding: 0;
                margin: 0;

                position: relative;
                display: block;
            }
        }
    }
}
