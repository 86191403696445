@font-face {
    font-family: 'Helvetica Bold';
    src: url('../../fonts/helvetica/HelveticaNeueLTStd-Bd.woff2') format('woff2'),
    url('../../fonts/helvetica/HelveticaNeueLTStd-Bd.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Medium';
    src: url('../../fonts/helvetica/HelveticaNeueLTStd-Md.woff2') format('woff2'),
    url('../../fonts/helvetica/HelveticaNeueLTStd-Md.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Roman';
    src: url('../../fonts/helvetica/HelveticaNeueLTStd-Roman.woff2') format('woff2'),
    url('../../fonts/helvetica/HelveticaNeueLTStd-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}