.page-error {
    width: 100%;
    min-height: 100vh;

    background: $white;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    padding-top: 100px;
    padding-left: 30px;
    padding-right: 30px;

    .logo-container {
        width: 100%;
        height: auto;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        position: relative;

        margin-bottom: 48px;

        p {
            width: 100%;

            font-family: $helveticaMedium;
            font-size: 16px;
            font-weight: normal;
            line-height: 1.25;
            text-align: center;

            padding: 0;
            margin: 0;

            color: $orange;
        }
    }

    h1 {
        width: 100%;

        font-size: 100px;
        font-family: "Helvetica Bold", Arial, sans-serif;
        font-weight: bold;
        color: #eae9f2;

        text-align: center;

        //                font-family: $helveticaBold;
                font-weight: normal;
        //font-size: 28px;
        //line-height: normal;
        //
        //color: $blue-med;

        margin: 0;
        padding: 0;
    }

    p {
        font-size: 20px;
        font-family: "Helvetica Bold", Arial, sans-serif;
        font-weight: bold;
        color: #eae9f2;
        text-align: center;

        //font-family: $helveticaRegular;
        //font-size: 16px;
        //line-height: 1.5;
        //
        //
        //padding: 0;
        //margin: 16px 0 32px 0;
        //
        //color: $blue-grey;

        display: inline-block;
        margin: 0;

        a {
                            font-family: $helveticaBold;
                font-weight: normal;
            color: $blue-dark;
            text-decoration: none;

            padding-right: 35px;

            background: url('../../image/icons/arrow-right.svg') 95% center no-repeat;
            background-size: auto 14px;

            transition: $transition;

            &:hover {
                background-position: 100% center;
            }
        }
    }

    p.small {
        margin-bottom: 32px;
        font-size: 16px;
        font-family: "Helvetica Roman", Arial, sans-serif;
        font-weight: 400;
        color: #eae9f2;
        text-align: center;
    }
}
