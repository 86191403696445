.modal {
    width: 100%;
    height: 100vh;

    margin: 0;
    padding: 0;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    position: absolute;
    top: 0;
    left: 0;

    padding-top: 300px;

    background: rgba(0, 0, 0, 0.18);

    opacity: 1;

    z-index: 9999;

    animation-name: modalopen;
    animation-duration: .5s;

    .modal__content {
        width: auto;
        max-width: 320px;
        height: auto;

        padding: 32px;
        background: $white;

        border-radius: 10px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        position: relative;

        form{
            align-items: center;

            button:first-of-type{
                margin-bottom: 10px;
            }
        }

        p {
            text-align: center;
            padding: 0;

            &.title {
                font-family: $helveticaBold;
                font-weight: normal;
                font-size: 20px;
                line-height: 24px;
                color: $blue-med;

                margin: 0 0 10px 0;
            }

            &.copy {
                font-family: $helveticaRegular;
                font-size: 16px;
                line-height: 20px;
                color: $blue-grey;

                margin: 0 0 36px 0;
            }
        }

        .cancel {
            width: auto;
            height: auto;

            font-family: $helveticaMedium;
            font-size: 16px;
            line-height: 1.5;
            text-align: center;

            padding: 0;
            margin: 16px 0 0 0;

            color: $blue-med;

            background: transparent;
            border: none;

            transition: $transition;

            &:hover {
                color: $blue;
            }
        }
    }
}

@keyframes modalopen {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}