.header-onboarding {
    width: 100%;
    height: auto;

    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin: 0 0 100px 0;
    padding: 50px 0 0 0;

    @media (max-width: 1200px) {
        margin: 0 0 60px 0;
        padding: 90px 0 0 0;
    }

    .logo {
        margin-bottom: 10px;
    }

    p {
        font-family: $helveticaMedium;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.25;
        text-align: center;

        padding: 0;
        margin: 0 0 15px 0;

        color: $orange;

        &:last-of-type {
            width: 340px;

            color: $blue-grey;

            margin-bottom: 0;
        }
    }
}

.header-onboarding-mob {
    width: 100%;
    height: auto;

    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin: 0 0 60px 0;
    padding: 90px 0 0 0;

    @media (max-width: 767px) {
        padding-top: 54px;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 46px;
    }

    .logo {
        margin-bottom: 10px;
    }

    p {
        max-width: 100%;
        font-family: $helveticaMedium;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.25;
        text-align: center;

        padding: 0;
        margin: 0 0 15px 0;

        color: $orange;

        &:last-of-type {
            width: 340px;

            color: $blue-grey;

            margin-bottom: 0;
        }
    }
}

.onboarding {
    width: 100%;

    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 0 45px;
    display: grid;

    align-items: center;

    .onboarding__content {
        width: 100%;

        justify-self: start;

        padding: 40px;

        background: $main-gradient;

        border-radius: 10px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        box-shadow: 0 30px 60px 0 rgba(0, 0, 0, .1);


        h1 {
            font-family: $helveticaBold;
            font-weight: normal;
            font-size: 28px;
            line-height: 33px;
            text-align: left;

            color: $white;

            padding: 0 0 15px 0;
            margin: 0;
        }

        h2 {
            font-family: $helveticaMedium;
            font-weight: normal;
            font-size: 16px;
            line-height: normal;
            text-align: left;

            color: $white;

            padding: 0 0 15px 0;
            margin: 0;
        }

        p {
            font-family: $helveticaRegular;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;

            color: $white;

            padding: 0;
            margin: 0;
        }
    }
}

.onboarding-mob {
    width: 100%;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-gap: 45px 30px;
    display: grid;

    @media (max-width: 960px) {
        grid-gap: 32px 30px;
        align-items: stretch;
    }

    @media (max-width: 767px) {
        grid-gap: 15px 15px;
    }


    .onboarding__content {
        width: 100%;

        justify-self: start;

        padding: 35px;

        background: $main-gradient;

        border-radius: 10px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        box-shadow: 0 30px 60px 0 rgba(0, 0, 0, .1);

        @media (max-width: 960px) {
            min-height: 200px;
            grid-column: 1 / span 2;
        }

        @media (max-width: 768px) {
            padding: 24px;
        }

        @media (max-width: 767px) {
            width: calc(100% - 40px);
            margin-left: 20px;
            margin-right: 20px;
        }

        &:nth-of-type(2) {
            h1 {
                max-width: 75%;
            }

            @media (max-width: 767px) {
                margin-bottom: 220px;
            }
        }

        &:last-of-type {
            background: $white;

            margin-bottom: 40px;

            @media (max-width: 960px) {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }

            @media (max-width: 767px) {
                width: 100%;
                border-radius: 0;

                margin-left: 0;
                margin-right: 0;
                margin-bottom: 0;

                position: fixed;
                bottom: 0;
                left: 0;

                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
            }


            h1 {
                font-size: 28px;
                color: $blue-dark;
                line-height: 33px;
                margin-bottom: 10px !important;

                @media (max-width: 767px) {
                    font-size: 20px;
                    line-height: 24px;
                    margin-bottom: 16px !important;
                }
            }

            h2 {
                font-size: 22px;
                color: $blue-grey;
                margin-bottom: 27px !important;

                @media (max-width: 960px) {
                    margin-bottom: 0 !important;
                }

                @media (max-width: 767px) {
                    font-family: $helveticaRegular;
                    font-size: 16px;
                    margin-bottom: 16%;
                }
            }

            .title {
                width: 100%;
                height: auto;

                position: relative;
                display: block;

                @media (max-width: 960px) {
                    width: 50%;
                }

                @media (max-width: 767px) {
                    width: 100%;
                }
            }

            .cta-container {
                width: 100%;
                height: auto;

                display: flex;
                align-items: center;
                justify-content: flex-start;

                position: relative;

                @media (max-width: 960px) {
                    flex-direction: column;
                    align-items: flex-end;
                }

                @media (max-width: 767px) {
                    flex-direction: row;
                    align-items: flex-start;
                }

                .app-store {
                    width: 136px;
                    height: 40px;

                    display: block;
                    position: relative;

                    background: url('../../image/app-store.svg') center center no-repeat;
                    background-size: 100% auto;

                    margin-right: 15px;

                    @media (max-width: 960px) {
                        margin-right: 0;
                        margin-bottom: 15px;
                    }

                    @media (max-width: 767px) {
                        margin-right: 15px;
                        margin-bottom: 0;
                    }
                }

                .google-play {
                    width: 136px;
                    height: 40px;

                    display: block;
                    position: relative;

                    background: url('../../image/google-play.svg') center center no-repeat;
                    background-size: 100% auto;
                }
            }
        }

        h1 {
            font-family: $helveticaBold;
            font-weight: normal;
            font-size: 22px;
            line-height: 26px;
            text-align: left;

            color: $white;

            padding: 0 0 15px 0;
            margin: 0;

            @media (max-width: 767px) {
                font-size: 20px;
                line-height: 24px;
            }
        }

        h2 {
            font-family: $helveticaMedium;
            font-weight: normal;
            font-size: 16px;
            line-height: normal;
            text-align: left;

            color: $white;

            padding: 0 0 15px 0;
            margin: 0;
        }

        p {
            font-family: $helveticaRegular;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;

            color: $white;

            padding: 0;
            margin: 0;
        }
    }
}