.header-login {
    width: 1440px;
    min-width: 1440px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    margin: 0 auto 110px auto;
    padding: 50px 150px 0 150px;
    position: relative;
    @media (max-width: 1440px) {
        width: 100%;
        min-width: unset;
        padding-left: 30px;
        padding-right: 30px;
    }
    .logo {
        margin-bottom: 10px;
    }
    p {
        font-family: $helveticaMedium;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.25;
        text-align: left;
        padding: 0;
        margin: 0;
        color: $orange;
    }
}

.login__modal {
    width: auto;
    height: auto;
    padding: 16px 20px;
    background: $white;
    border: 1px solid $white-dark;
    border-radius: 8px;
    position: absolute;
    top: -70px;
    left: 150px;
    display: block;
    p.title {
        font-family: $helveticaBold;
        font-size: 16px;
        line-height: normal;
        text-align: left;
        color: $blue-med;
        padding: 0;
        margin: 0 0 16px 0;
    }
    p.copy {
        font-family: $helveticaRegular;
        font-size: 14px;
        line-height: normal;
        text-align: left;
        color: $blue-grey;
        padding: 0;
        margin: 0;
    }
    button.close {
        width: 12px;
        height: 12px;
        position: absolute;
        top: 16px;
        right: 16px;
        margin: 0;
        padding: 0;
        img {
            width: 100%;
            height: auto;
            position: relative;
            display: block;
        }
    }
}

.login {
    width: 100%;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
        grid-template-columns: 40% 50%;
    }
    grid-template-columns: 330px 664px;
    grid-template-rows: auto;
    display: grid;
    align-items: flex-start;
    justify-content: space-between;
    .login__form {
        grid-column: 1 / span 1;
        h1 {
            font-family: $helveticaBold;
            font-size: 24px;
            line-height: normal;
            text-align: left;
            color: $blue-med;
            padding: 0;
            margin: 0 0 32px 0;
        }
        form {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            label {
                width: 100%;
                font-family: $helveticaRegular;
                font-size: 12px;
                line-height: normal;
                text-align: left;
                color: $blue-grey;
                padding: 0;
                margin: 15px 0 15px 0;
            }
            input {
                width: 100%;
                height: 50px;
                font-family: $helveticaRegular;
                font-size: 16px;
                font-weight: normal;
                line-height: normal;
                color: $blue-med;
                border: 1px solid $white-dark;
                border-radius: 4px;
                background: $white;
                padding: 0 20px;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                &.input-error {
                    border-color: $red;
                }
                &::placeholder {
                    color: $light-periwinkle;
                }
            }
            p.error {
                font-family: $helveticaMedium;
                font-weight: normal;
                font-size: 12px;
                line-height: normal;
                text-align: left;
                color: $red;
                padding: 0;
                margin: 0;
            }
            a.recover {
                font-family: $helveticaMedium;
                font-weight: normal;
                font-size: 12px;
                line-height: normal;
                text-align: right;
                text-decoration: none;
                align-self: flex-end;
                color: $blue-dark;
                padding: 0;
                margin: 0;
            }
        }
        p {
            font-family: $helveticaRegular;
            font-size: 16px;
            line-height: 1.5;
            margin-top: 24px;
            margin-bottom: 0;
            padding: 0;
            color: $blue-grey;
            display: inline-block;
            a {
                font-family: $helveticaBold;
                color: $blue-dark;
                text-decoration: none;
                padding-right: 35px;
                background: url('../../image/icons/arrow-right.svg') 95% center no-repeat;
                background-size: auto 14px;
                transition: $transition;
                &:hover {
                    background-position: 100% center;
                }
            }
        }
        .button-primary {
            margin-top: 46px;
        }
    }
    .image__form,
    .login__image {
        grid-column: 2 / span 1;
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
            img {
                width: 100%;
            }
        }
    }
}