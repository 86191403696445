.previewVerbal {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    padding-right: 16px;

    img {
        margin-bottom: 32px;
    }

    p {
        text-align: left;
        padding: 0;
        position: relative;
        display: block;
    }

    p.title {
        font-family: $helveticaBold;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;

        color: $blue-med;

        margin: 0 0 32px 0;
    }

    p.subtitle {
        font-family: $helveticaMedium;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;

        color: $blue-med;

        margin: 0 0 22px 0;
    }

    p.caption {
        font-family: $helveticaRegular;
        font-size: 14px;
        line-height: 16px;

        color: $blue-grey;

        margin: 0 0 10px 0;
    }

    a, p.code {
        font-family: $helveticaRegular;
        font-size: 16px;
        line-height: 20px;

        color: $blue-med;
        margin-bottom: 123px;
    }

    a {
        text-decoration: none;
        margin-bottom: 24px;

        &:hover {
            text-decoration: underline;
        }
    }

    .button-primary {
        align-self: flex-end;
    }

    input {
        margin-bottom: 24px;

        &:last-of-type {
            margin-bottom: 63px;
        }
    }

    .close {
        width: 16px;
        height: 17px;

        background: url('../../../image/icons/close-blue.svg') center center no-repeat;
        background-size: cover;

        position: absolute;
        top: 24px;
        right: 40px;

        cursor: pointer;
    }
}