.verbalCenter {
    width: 424px;
    height: auto;
    min-height: 100vh;
    padding: 30px 34px 30px 24px;
    position: fixed;
    left: 428px;
    top: 118px;
    .verbalCenter__header {
        width: 100%;
        height: auto;
        margin-bottom: 40px;
        .flex-row {
            justify-content: space-between;
            margin-bottom: 26px;
        }
        .tab {
            width: 100%;
            height: auto;
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            padding: 0;
            margin: 0 0 24px 0;
            a {
                font-family: $helveticaBold;
                font-weight: normal;
                font-size: 20px;
                line-height: normal;
                text-align: left;
                color: $blue-grey;
                text-decoration: none;
                padding: 0 0 12px 0;
                margin: 0 24px 0 0;
                transition: $transition;
                border-bottom: 4px solid transparent;
                &:last-of-type {
                    margin-right: 0;
                }
                &:hover,
                &.active {
                    color: $blue-med;
                    border-bottom: 4px solid $blue;
                }
                &.active {
                    cursor: none;
                    pointer-events: none;
                }
            }
        }
    }
    .verbalCenter__list {
        width: 100%;
        max-height: calc(100vh - 288px);
        //height: auto;
        display: flex;
        //flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        overflow-y: auto;
        .verbalCenter__list__item {
            width: 100%;
            height: 100;
            grid-template-columns: 57px 1fr;
            grid-template-rows: auto;
            grid-gap: 0 16px;
            display: grid;
            padding: 16px 16px 0 16px;
            align-items: center;
            margin-bottom: 12px;
            position: relative;
            border-radius: 8px;
            border: 1px solid transparent;
            text-decoration: none;
            transition: $transition;
            cursor: pointer;
            &:last-of-type {
                padding-bottom: 16px;
            }
            &:hover {
                border: 1px solid $blue-med;
            }
            .icon {
                grid-column: 1 / span 1;
                grid-row: 1 / span 1;
                position: relative;
                display: block;
                padding-bottom: 16px;
                img {
                    width: 100%;
                    height: auto;
                    position: relative;
                    display: block;
                }
            }
            .content {
                width: auto;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                padding-bottom: 16px;
                border-bottom: 1px solid $light-grey;
                transition: $transition;
                .content__copy {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-between;
                    padding: 0;
                    margin: 0;
                    position: relative;
                    .hr {
                        width: 100%;
                        height: 1px;
                        background: $light-grey;
                        position: relative;
                        display: block;
                        margin-top: 24px;
                    }
                    p.user {
                        font-family: $helveticaBold;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        text-align: left;
                        color: $blue-med;
                        padding: 0;
                        margin: 0;
                        position: relative;
                        display: block;
                    }
                    p.date {
                        font-family: $helveticaRegular;
                        font-size: 14px;
                        line-height: normal;
                        text-align: left;
                        color: $blue-med;
                        padding: 0;
                        margin: 3px 0 0 0;
                        position: relative;
                        display: block;
                    }
                    p.author {
                        font-family: $helveticaMedium;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 1;
                        text-align: left;
                        color: $blue-med;
                        padding: 0;
                        margin: 16px 0 0 0;
                        position: relative;
                        display: block;
                        span {
                            padding: 7px;
                            margin: 0;
                            border-radius: 4px;
                            background: $grey;
                        }
                        span.di {
                            font-family: $helveticaRegular;
                            color: $blue-grey;
                        }
                        a {
                            color: $blue-dark;
                            text-decoration: underline;
                            margin-left: 6px;
                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }
                .content__icon {
                    width: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 18px;
                        height: 12px;
                        position: relative;
                        display: block;
                        right: 0;
                        transition: $transition;
                    }
                }
            }
            &.active {
                border: 1px solid $blue-light;
            }
            &:hover {
                .content {
                    border-bottom: 1px solid transparent;
                }
                .content__icon {
                    img {
                        right: -5px;
                    }
                }
            }
        }
    }
}