.header-signup {
    width: 1440px;
    min-width: 1440px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    margin: 0 auto 44px auto;
    padding: 50px 150px 0 150px;
    position: relative;
    @media (max-width: 1440px) {
        width: 100%;
        min-width: unset;
        padding-left: 30px;
        padding-right: 30px;
    }
    .logo {
        margin-bottom: 10px;
    }
    p {
        font-family: $helveticaMedium;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.25;
        text-align: left;
        padding: 0;
        margin: 0;
        color: $orange;
    }
}

.signup {
    width: 100%;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
        grid-template-columns: 50% 40%;
    }
    grid-template-columns: 508px 330px;
    grid-template-rows: auto;
    display: grid;
    align-items: center;
    justify-content: space-between;
    .signup__form {
        grid-column: 2 / span 1;
        form {
            align-items: stretch;
        }
        h1 {
            font-family: $helveticaBold;
            font-weight: normal;
            font-size: 24px;
            line-height: normal;
            text-align: left;
            color: $blue-med;
            padding: 0;
            margin: 0 0 32px 0;
        }
        p {
            font-family: $helveticaRegular;
            font-size: 16px;
            line-height: 1.5;
            margin-top: 24px;
            margin-bottom: 0;
            padding: 0;
            color: $blue-grey;
            display: inline-block;
            a {
                font-family: $helveticaBold;
                font-weight: normal;
                color: $blue-dark;
                text-decoration: none;
                padding-right: 35px;
                background: url('../../image/icons/arrow-right.svg') 95% center no-repeat;
                background-size: auto 14px;
                transition: $transition;
                &:hover {
                    background-position: 100% center;
                }
            }
        }
        .gdpr,
        .welcome {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            p.title {
                font-family: $helveticaBold;
                font-weight: normal;
                font-size: 24px;
                line-height: 1.5;
                text-align: left;
                color: $blue-med;
                padding: 0;
                margin: 0 0 24px 0;
            }
            p.copy {
                font-family: $helveticaRegular;
                font-size: 16px;
                line-height: 25px;
                text-align: left;
                color: $blue-med;
                padding: 0;
                margin: 0 0 24px 0;
            }
            .button-primary {
                align-self: flex-end;
            }
        }
        .welcome {
            p.copy {
                color: $blue-grey;
            }
        }
        .button-primary {
            margin-top: 0;
        }
        .pagination {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 48px;
            position: relative;
            button.back {
                width: 20px;
                height: 20px;
                background: url('../../image/icons/arrow-left.svg') center center no-repeat;
                background-size: 100% auto;
                position: relative;
                display: block;
                left: 0;
                transition: $transition;
                &:hover {
                    left: -5px;
                }
            }
            .pagination__pagination {
                width: auto;
                height: auto;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                span {
                    width: 8px;
                    height: 8px;
                    background: $light-grey;
                    border-radius: 50%;
                    position: relative;
                    display: block;
                    margin-right: 12px;
                    cursor: pointer;
                    transition: $transition;
                    &:last-of-type {
                        margin-right: 0;
                    }
                    &.active,
                    &:hover {
                        background: $blue-light;
                    }
                    &.active {
                        cursor: auto;
                        &:hover {
                            background: $blue-light;
                        }
                    }
                    &.disabled {
                        cursor: not-allowed;
                        &:hover {
                            background: $light-grey;
                        }
                    }
                }
            }
        }
    }
    .signup__image {
        grid-column: 1 / span 1;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding-top: 10px;
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
            img {
                width: 320px;
            }
        }
    }
}