.agenda__detail {
    width: 436px;
    height: auto;
    min-height: 100vh;
    background: rgb(252, 252, 252);
    border-top: 1px solid rgb(246, 246, 246);
    border-right: none;
    border-left: 1px solid $white-dark;
    border-bottom: none;
    border-radius: 20px 0 0 20px;
    margin: 0;
    padding: 30px 0 30px 0;
    box-shadow: -5px 0 10px 0 rgba(0, 0, 0, 0.03);
    position: fixed;
    top: 118px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    transition: all .5s ease-in-out;
    &.closed {
        right: -100%;
        .header {
            .close-right {
                transform: rotate(-180deg);
                transition: $transition;
            }
        }
        .agenda__detail__form,
        .agenda__detail__detail {
            padding-left: 72px;
            transition: all .5s ease-in-out;
        }
        .cta-container {
            right: -364px;
        }
    }
    .header {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 32px;
        padding-right: 32px;
        padding-left: 32px;
        .close-right {
            width: 10px;
            height: auto;
            position: relative;
            display: block;
            cursor: pointer;
            padding: 0;
            margin: 0 32px 0 0;
            img {
                width: 100%;
                height: auto;
                position: relative;
                display: block;
            }
        }
        .close {
            width: 17px;
            height: 17px;
            position: relative;
            display: block;
            padding: 0;
            margin: 0 0 0 auto;
            background: url('../../image/icons/close.svg') center center no-repeat;
            background-size: 100%;
            cursor: pointer;
        }
        p {
            font-family: $helveticaBold;
            font-weight: normal;
            font-size: 20px;
            line-height: 1;
            text-align: left;
            color: $blue-med;
            margin: 0;
            padding: 0;
        }
        .menu {
            width: 24px;
            height: 6px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            cursor: pointer;
            margin: 0 0 0 auto;
            padding: 0;
            span {
                width: 5px;
                height: 5px;
                border-radius: 50%;
                position: relative;
                display: block;
                background: $blue-grey;
                margin: 0;
                padding: 0;
                top: 0;
                transition: $transition;
                &:first-of-type {}
                &:nth-of-type(2) {
                    transition-delay: .2s;
                }
                &:last-of-type {
                    transition-delay: .3s;
                }
            }
            &:hover {
                span:first-of-type {
                    top: -5px;
                }
                span:nth-of-type(2) {
                    top: -5px;
                    transition-delay: .2s;
                }
                span:last-of-type {
                    top: -5px;
                    transition-delay: .3s;
                }
            }
        }
        .menu-detail {
            width: 190px;
            height: auto;
            background: $white;
            border: 1px solid $white-dark;
            border-radius: 8px;
            padding: 0;
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.03);
            position: absolute;
            top: 55px;
            right: 18px;
            &:after,
            &:before {
                content: '';
                display: block;
                position: absolute;
                right: 15px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 10px;
            }
            &:after {
                top: -20px;
                border-color: transparent transparent rgb(255, 255, 255) transparent;
            }
            /* this border color controls the outside, thin border */
            &:before {
                top: -21px;
                border-color: transparent transparent $white-dark transparent;
            }
            ul {
                width: 100%;
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                list-style: none;
                li {
                    width: 100%;
                    text-align: left;
                    margin: 0;
                    padding: 0;
                    &:first-of-type {
                        a {
                            border-top-left-radius: 8px;
                            border-top-right-radius: 8px;
                        }
                    }
                    &:last-of-type {
                        a {
                            color: $red;
                            border-bottom: none;
                            border-bottom-left-radius: 8px;
                            border-bottom-right-radius: 8px;
                        }
                    }
                    a {
                        width: 100%;
                        height: auto;
                        margin: 0;
                        font-family: $helveticaMedium;
                        font-size: 16px;
                        line-height: 1;
                        text-decoration: none;
                        color: $blue-dark;
                        border-bottom: 1px solid $white-dark;
                        padding: 12px 15px;
                        position: relative;
                        display: block;
                        transition: $transition;
                        &:hover {
                            background-color: $white-dark;
                        }
                    }
                }
            }
        }
    }
}