@import '~react-datepicker/dist/react-datepicker.css';
@import '/scss/variables';

/*---------- Base -----------*/

@import '/scss/base/button',
'/scss/base/typo',
'/scss/base/form';

/*---------- Layout -----------*/

@import '/scss/layout/wrapper',
'/scss/layout/onboarding',
'/scss/layout/login',
'/scss/layout/signup',
'/scss/layout/review',
'/scss/layout/agenda',
'/scss/layout/profile',
'/scss/layout/loading',
'/scss/layout/page-error';

/*---------- Components -----------*/

@import 'scss/components/AgendaAgenda',
'scss/components/Commitment',
'scss/components/AgendaDetails',
'scss/components/AgendaDetails/AgendaDetailsDetail',
'scss/components/AgendaDetails/AgendaDetailsForm',
'scss/components/Calendar',
'scss/components/VerbalCenter',
'scss/components/VerbalRight',
'scss/components/VerbalRight/CreateVerbal',
'scss/components/VerbalRight/PreviewVerbal',
'scss/components/VerbalModal',
'scss/components/ModifyVerbal',
'scss/components/AddVerbal';
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    margin: 0;
    background: $white;
}

* {
    outline: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

.clearfix {
    clear: both;
}

.rspnsv {
    width: 100%;
    max-width: 100%;
    display: block;
}

#root {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    padding: 0;
    margin: 0;
    background: $white;
    //position: fixed;
    //height: 100%;
    //width: 100%;
    //top: 0;
    //left: 0;
    //background: $white;
    //display: flex;
    //transform: translate(10);
}

.flex-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.unvisible {
    visibility: hidden;
}

.outline {
    box-shadow: 0 0 10px rgba(47, 48, 71, .5);
}

a:focus {
    outline: rgba(47, 48, 71, .2) auto 2px;
}

.logo {
    cursor: pointer;
}

.header-onboarding-mob,
.wrapper-on-mob,
.onboarding-cntr-mob,
#mobile {
    display: none;
    /* iPhone Portrait and Landscape */
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
        display: block;
    }
    /* iPad Portrait */
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
        display: block;
    }
    /* ----------- Retina Screens ----------- */
    @media not all and (min-width: 1024px) {
        display: block;
    }
}

.header-onboarding,
.wrapper-on,
.onboarding-cntr,
#root {
    display: none;
    /* iPad Landscape */
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
        display: block;
    }
    /* ----------- Retina Screens ----------- */
    @media screen and (min-width: 1024px) {
        display: block;
    }
}