form {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    label {
        font-family: $helveticaRegular;
        font-size: 12px;
        line-height: normal;
        text-align: left;

        display: block;

        color: $blue-grey;

        padding: 0;
        margin: 15px 0 15px 0;
    }

    input {
        width: 100%;
        height: 50px;

        font-family: $helveticaRegular;
        font-size: 16px;
        font-weight: normal;
        line-height: normal;

        color: $blue-med;

        border: 1px solid $white-dark;
        border-radius: 4px;

        background: $white;

        padding: 0 20px 0 12px;
        margin-bottom: 10px;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.02);

        &.input-error {
            border-color: $red;
        }

        &::placeholder {
            color: $light-periwinkle;
        }

        &:focus {
            box-shadow: 0 0 10px rgba(47, 48, 71, .5);
        }

        &:read-only, &:disabled{
            color: $blue-grey;
            background: $grey;
            -webkit-text-fill-color: $blue-grey;
        }
    }

    textarea {
        width: 100%;
        height: 140px;

        font-family: $helveticaRegular;
        font-size: 16px;
        font-weight: normal;
        line-height: normal;

        color: $blue-med;

        border: 1px solid $white-dark;
        border-radius: 4px;

        background: $white;

        padding: 15px 20px 15px 12px;
        margin-bottom: 10px;

        resize: none;

        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.02);

        &.input-error {
            border-color: $red;
        }

        &::placeholder {
            color: $light-periwinkle;
        }

        &:focus {
            box-shadow: 0 0 10px rgba(47, 48, 71, .5);
        }
    }

    p.error {
        width: 100%;
        font-family: $helveticaMedium !important;
        font-size: 12px !important;
        line-height: normal !important;
        text-align: left !important;

        color: $red !important;

        padding: 0 !important;
        margin: 0 !important;
    }

    a.recover {
        font-family: $helveticaMedium;
        font-size: 12px;
        line-height: normal;
        text-align: right;
        text-decoration: none;

        align-self: flex-end;

        color: $blue-dark;

        padding: 0;
        margin: 0;
    }

    .button-help {
        width: 15px;
        height: 15px;

        background: url('../../image/icons/help.svg') center center no-repeat;
        background-size: 100%;

        margin-left: 8px;

        position: relative;
        display: block;
    }

    .password-container {
        width: 100%;
        min-height: 78px;
        height: auto;

        position: relative;
        display: block;

        span.unhide {
            width: 21px;
            height: 14px;

            background: url('../../image/icons/eye-slah.svg') center center no-repeat;
            background-size: cover;

            position: absolute;
            display: block;

            top: 18px;
            right: 14px;

            cursor: pointer;

            transition: .2s linear;

            &.hide, &:hover {
                background: url('../../image/icons/eye.svg') center center no-repeat;
                background-size: cover;

                transition: .2s linear;
            }
        }
    }

    .help-tooltip {
        width: 328px;
        height: auto;

        background: $white;

        border: 1px solid $white-dark;
        border-radius: 8px;

        padding: 16px;

        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.03);

        position: absolute;
        top: -110px;
        right: -246px;

        z-index: 10;

        p.title {
            font-family: $helveticaMedium;
            font-size: 16px;
            line-height: 1;
            text-align: left;

            color: $blue-med;

            padding: 0;
            margin: 0 0 10px 0;
        }

        p.copy {
            font-family: $helveticaRegular;
            font-size: 14px;
            line-height: 16px;
            text-align: left;

            color: $blue-grey;

            padding: 0;
            margin: 0;
        }

        .close {
            width: 13px;
            height: 13px;

            position: absolute;
            top: 16px;
            right: 16px;

            display: block;

            cursor: pointer;
        }

        &:after,
        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 60px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 13px;
        }

        &:after {
            bottom: -24px;
            border-color: rgb(255, 255, 255) transparent transparent transparent;
        }

        /* this border color controls the outside, thin border */
        &:before {
            bottom: -25px;
            border-color: $white-dark transparent transparent transparent;
        }
    }

    .checkbox-container {
        width: 100%;
        height: auto;

        margin-top: 20px;

        .checkbox-content {
            width: 100%;

            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            padding-top: 20px;

            &:last-of-type {

                a {
                    border: none;
                }
            }

            .check {
                width: 24px;
                height: 24px;

                border-radius: 4px;
                border: none;

                background: $grey;

                padding: 0;
                margin-right: 12px;

                cursor: pointer;

                position: relative;
                display: block;

                span {
                    width: 14px;
                    height: 12px;

                    background: url('../../image/icons/checkmark.svg') center center no-repeat;
                    background-size: 100%;

                    position: absolute;
                    top: 50%;
                    left: 50%;

                    transform: translate(-50%, -50%);

                    opacity: 0;

                    transition: $transition;
                }

                &.checked {
                    span {
                        opacity: 1;
                    }
                }
            }

            input[type=checkbox] {
                width: 0;
                height: 0;
                opacity: 0;
            }

            a {
                font-family: $helveticaRegular;
                font-size: 16px;
                line-height: 1.5;
                text-align: left;
                text-decoration: none;

                flex: 1;

                color: $blue-med;

                background: url('../../image/icons/arrow-right.svg') 98% 5px no-repeat;
                background-size: 16px auto;

                padding: 0 24px 12px 0;
                margin: 0;

                transition: $transition;

                border-bottom: 1px solid $grey;

                &:hover {
                    background-position: 100% 5px;
                }
            }
        }
    }

    .date-container {
        width: 100%;
        height: auto;

        display: flex;
        align-self: flex-start;
        justify-content: space-between;

        input {
            text-align: center;
            padding-left: 10px;
            padding-right: 10px;

            &:first-of-type {
                width: 70px;
            }

            &:last-of-type {
                width: 70px;
            }
        }

        .select-container {
            width: auto;
            height: auto;

            position: relative;
            display: block;

            span {
                width: 14px;
                height: 48px;

                content: '';

                top: 1px;
                right: 1px;

                position: absolute;
                display: block;

                pointer-events: none;

                background: url('../../image/icons/arrow-select.svg') center center no-repeat $white;

                z-index: 2;

                padding-right: 30px;
            }

            &.read-only {
                span {
                    background: url('../../image/icons/arrow-select-disabled.svg') center center no-repeat $grey;
                    background-size: 14px auto;
                }

                select:disabled {
                    color: $blue-grey;
                }
            }
        }

        select {
            width: 155px;
            height: 50px;

            font-family: $helveticaRegular;
            font-size: 16px;
            font-weight: normal;
            line-height: normal;
            text-align: left;

            color: $blue-med;

            background: $white;

            border: 1px solid $white-dark;
            border-radius: 4px;

            padding: 0 18px 0 15px;
            margin-bottom: 10px;

            display: flex;
            align-items: center;
            justify-content: flex-start;

            position: relative;

            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.02);

            -webkit-appearance: none;

            &:disabled {
                background: $grey;
            }

            &:focus {
                box-shadow: 0 0 10px rgba(47, 48, 71, .5);
            }
        }
    }

    .react-datepicker-wrapper {
        width: 100%;

        input {
            text-transform: capitalize;
        }

    }

    .switch {
        position: relative;
        display: inline-block;
        width: 53px;
        height: 30px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 8px;
        background-color: $light-grey;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 22px;
        width: 22px;
        left: 4px;
        bottom: 4px;

        border-radius: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: $blue;
    }

    input:focus + .slider {
        box-shadow: 0 0 10px rgba(47, 48, 71, .5) !important;
        //box-shadow: none;
    }

    input:checked + .slider:before {
        transform: translateX(22px);
    }

    .react-autosuggest__container {
        width: 100%;

        position: relative;

        .react-autosuggest__suggestions-container--open {
            width: 100%;
            max-height: 200px;
            height: auto;

            background: $white;

            border: 1px solid $white-dark;
            border-radius: 4px;

            padding: 0;
            margin-top: 0;

            box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);

            //position: relative;
            position: absolute;
            display: block;

            overflow: auto;

            z-index: 9999;

            ul {
                width: 100%;

                margin: 0;
                padding: 0;

                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                list-style: none;

                li {
                    width: 100%;
                    height: auto;

                    margin: 0;
                    padding: 6px 12px;

                    font-family: $helveticaRegular;
                    font-size: 16px;
                    line-height: 20px;
                    text-decoration: none;
                    color: $blue-med;

                    border: none;
                    border-radius: 4px;

                    position: relative;
                    display: block;

                    cursor: pointer;

                    &:hover {

                        .suggestion-container {
                            color: $white !important;
                            background: $main-gradient !important;
                        }
                    }

                    .suggestion-container {
                        padding: 3px 5px;
                        border-radius: 4px;
                        transition: $transition;

                        &.hilighted {
                            color: $blue-med;
                            background: $light-periwinkle;
                        }
                    }


                    span.highlighted {
                        font-family: $helveticaMedium;
                        background: $light-periwinkle;
                    }
                }
            }

        }
    }

    .cancelable {
        width: calc(100% - 36px);

        position: relative;
        display: inline-block;
    }

    .input-trash {
        width: 36px;
        height: 100%;

        position: relative;
        display: inline-block;

        background: transparent;

        img {
            width: 17px;
            height: auto;
            position: relative;
            display: block;

            padding: 0;
            margin: 0 auto;

            transform: rotate(0);

            transition: $transition;
        }

        &:hover {
            img {
                animation: animation-trash;
                animation-duration: .5s;
            }
        }
    }

    @keyframes animation-trash {
        0% {
            transform: rotate(15deg)
        }
        25% {
            transform: rotate(-15deg)
        }
        50% {
            transform: rotate(15deg)
        }
        75% {
            transform: rotate(-15deg)
        }
        100% {
            transform: rotate(0deg)
        }
    }
}