.commitment {
    width: 416px;
    height: calc(100vh - 118px);
    //height: auto;
    //min-height: 100vh;
    padding: 30px 0 30px 24px;
    position: fixed;
    left: 428px;
    top: 118px;
    .commitment__header {
        width: 100%;
        height: auto;
        margin-bottom: 40px;
        .flex-row {
            justify-content: space-between;
            margin-bottom: 26px;
        }
        h1 {
            font-family: $helveticaBold;
            font-weight: normal;
            font-size: 20px;
            line-height: normal;
            text-align: left;
            color: $blue-med;
            padding: 0;
            margin: 0;
        }
        .commitment__header__cta {
            width: auto;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-right: 16px;
            .button-search {
                width: 24px;
                height: 24px;
                position: relative;
                display: block;
                margin-right: 24px;
                padding: 0;
                background: url('../../image/icons/search.svg') center center no-repeat;
                background-size: cover;
            }
            .button-filter {
                width: 24px;
                height: 24px;
                position: relative;
                display: block;
                padding: 0;
                background: url('../../image/icons/filter.svg') center center no-repeat;
                background-size: cover;
            }
        }
        .commitment__filter {
            width: 100%;
            height: auto;
            padding: 0;
            margin: 0;
            position: relative;
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            overflow: hidden;
            overflow-x: auto;
            -ms-overflow-style: none; // IE 10+
            scrollbar-width: none; // Firefox
            &::-webkit-scrollbar {
                display: none; // Safari and Chrome
            }
            .button-filter {
                width: auto;
                height: auto;
                font-family: $helveticaMedium;
                font-size: 16px;
                line-height: 1;
                color: $blue-grey;
                padding: 12px 16px;
                margin-right: 16px;
                border-radius: 18px;
                border: none;
                background: $grey;
                transition: $transition;
                &.app {
                    &:hover,
                    &.active {
                        color: $white;
                        background: $green;
                    }
                }
                &.ade {
                    &:hover,
                    &.active {
                        color: $white;
                        background: $violet;
                    }
                }
                &.udi {
                    &:hover,
                    &.active {
                        color: $white;
                        background: $brown;
                    }
                }
                &.avv {
                    &:hover,
                    &.active {
                        color: $white;
                        background: $orange;
                    }
                }
            }
        }
        .commitment__header__search {
            width: 324px;
            height: 50px;
            position: absolute;
            border-radius: 4px;
            background: #fff;
            top: 20px;
            left: 0;
            z-index: 2;
            overflow: visible;
            opacity: 0;
            transition: .3s;
            .close {
                position: absolute;
                z-index: 3;
                padding: 15px;
                width: 12px;
                height: 12px;
                top: 10.5px;
                left: 288px;
                background-size: 60%;
                cursor: pointer;
                opacity: 1;
                transition: $transition;
            }
            &.visible {
                top: -14px;
                opacity: 1;
                .close {
                    opacity: 1;
                }
            }
            input {
                padding-right: 35px;
            }
        }
    }
    .commitment__date {
        width: 100%;
        height: calc(100% - 104px);
        //height: auto;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-right: 10px;
        .commitment__date__content {
            width: 100%;
            grid-template-columns: 40px 300px;
            grid-template-rows: auto;
            grid-gap: 0 20px;
            display: grid;
            margin-bottom: 16px;
            border-bottom: 1px solid #f6f6f6;
            .commitment__date__content__date {
                grid-column: 1 / span 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 16px;
                padding-top: 5px;
                p.day {
                    font-family: $helveticaRegular;
                    font-size: 12px;
                    line-height: 1.3;
                    text-align: center;
                    color: $blue-grey;
                    text-transform: uppercase;
                    padding: 0;
                    margin: 0 0 8px 0;
                }
                p.number {
                    font-family: $helveticaBold;
                    font-weight: normal;
                    font-size: 20px;
                    line-height: 1;
                    text-align: center;
                    color: $blue-med;
                    padding: 0;
                    margin: 0;
                    &.today {
                        color: $blue;
                    }
                }
                &.today {
                    p.number {
                        color: $blue;
                    }
                }
            }
            .commitment__date__content__content {
                grid-column: 2 / span 1;
                padding: 12px 17px 12px 12px;
                margin-bottom: 12px;
                grid-template-columns: 15px 1fr;
                grid-template-rows: auto auto;
                grid-gap: 0 18px;
                display: grid;
                border-radius: 8px;
                position: relative;
                cursor: pointer;
                transition: $transition;
                .right-border {
                    grid-column: 1 / span 1;
                    grid-row: 1 / span 2;
                    position: relative;
                    display: flex;
                    align-items: center;
                    .right-border__content {
                        width: 3px;
                        height: 100%;
                        border-radius: 2px;
                        border: none;
                        position: relative;
                        display: block;
                    }
                    .right-border__alert {
                        width: 100%;
                        height: auto;
                        position: relative;
                        display: block;
                    }
                }
                .detail-name {
                    grid-column: 2 / span 1;
                    grid-row: 1 /span 1;
                    position: relative;
                    display: block;
                    p {
                        font-family: $helveticaMedium;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 1.2;
                        text-align: left;
                        margin: 0 0 6px 0;
                        padding: 0;
                        position: relative;
                        display: block;
                    }
                }
                .detail-date {
                    grid-column: 2 / span 1;
                    grid-row: 2 / span 1;
                    .detail-date-row {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                    }
                    p.day {
                        font-family: $helveticaRegular;
                        font-size: 12px;
                        line-height: 1.3;
                        text-align: left;
                        color: $blue-grey;
                        text-transform: capitalize;
                        margin: 0 0 4px;
                    }
                    p.time {
                        font-family: $helveticaRegular;
                        font-size: 12px;
                        line-height: 1.3;
                        text-align: left;
                        color: $blue-grey;
                        position: relative;
                        display: inline-block;
                        padding: 0;
                        margin: 0;
                        span {
                            width: 12px;
                            height: auto;
                            position: relative;
                            display: inline-block;
                            margin: 0 4px;
                            img {
                                max-width: 100%;
                                height: auto;
                                position: relative;
                                display: block;
                            }
                        }
                    }
                    p.kind {
                        font-family: $helveticaRegular;
                        font-size: 12px;
                        line-height: 1.3;
                        text-align: left;
                        position: relative;
                        display: inline-block;
                        padding: 0;
                        margin: 0 0 0 8px;
                    }
                }
                &.app {
                    &:hover,
                    &.selected {
                        background: $green10;
                    }
                    .right-border {
                        .right-border__content {
                            background: $green;
                        }
                    }
                    .detail-date {
                        p.kind {
                            color: $green;
                        }
                    }
                }
                &.ade {
                    &:hover,
                    &.selected {
                        background: $violet10;
                    }
                    .right-border {
                        .right-border__content {
                            background: $violet;
                        }
                    }
                    .detail-date {
                        p.kind {
                            color: $violet;
                        }
                    }
                }
                &.udi {
                    &:hover,
                    &.selected {
                        background: $brown10;
                    }
                    .right-border {
                        .right-border__content {
                            background: $brown;
                        }
                    }
                    .detail-date {
                        p.kind {
                            color: $brown;
                        }
                    }
                }
            }
            &.visible {
                p.number {
                    color: $blue;
                }
            }
            &.visible~.visible {
                p.number {
                    color: $blue-med;
                }
            }
        }
    }
    .search-result {
        .commitment__date__content {
            border-bottom: none;
            margin-bottom: 0;
        }
    }
}


/* ----------------------------------------------
 * Generated by Animista on 2019-11-10 19:59:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */


/**
 * ----------------------------------------
 * animation scale-in-tl
 * ----------------------------------------
 */

@keyframes scale-in-tl {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.scale-in-tl {
    animation: scale-in-tl 0.5s ease-in-out both;
    .none {
        font-family: $helveticaMedium;
        font-size: 16px;
        line-height: 1.2;
        text-align: left;
        margin: 0 0 6px 0;
        padding: 0;
        position: relative;
        display: block;
    }
}