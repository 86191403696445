.onboarding-cntr {
    min-height: 100vh;
    background: url('../../image/onboarding_bg.png') left bottom no-repeat;
    background-size: 100% 50%;
}

.wrapper-on {
    width: 100%;
    max-width: 1440px;
    padding-left: 120px;
    padding-right: 120px;
    margin: 0 auto;
    position: relative;
    display: block;
    @media (max-width: 1200px) {
        padding-left: 45px;
        padding-right: 45px;
    }
}

.wrapper-on-mob {
    width: 100%;
    max-width: 1200px;
    padding-left: 90px;
    padding-right: 90px;
    margin: 0 auto;
    position: relative;
    display: block;
    @media (max-width: 768px) {
        padding-left: 60px;
        padding-right: 60px;
    }
    @media (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.wrapper-login {
    width: 1440px;
    padding-left: 150px;
    padding-right: 150px;
    margin: 0 auto;
    position: relative;
    display: block;
    @media (max-width: 1440px) {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }
}