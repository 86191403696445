.react-datepicker,
.react-datepicker--time-only {
    width: 100%;
    background: transparent;
    border: none;
    border-radius: 0;
    .react-datepicker__navigation {
        width: 18px;
        height: 12px;
        top: 5px;
        border: none;
        &.react-datepicker__navigation--previous {
            background: url('../../image/icons/left-calendar.svg') center center no-repeat;
            background-size: 100%;
            left: unset;
            right: 37px;
            transition: $transition;
            &:hover {
                right: 42px;
            }
        }
        &.react-datepicker__navigation--next {
            background: url('../../image/icons/right-calendar.svg') center center no-repeat;
            background-size: 100%;
            right: 5px;
            transition: $transition;
            &:hover {
                right: 0px;
            }
        }
    }
    .react-datepicker__day--outside-month {
        visibility: hidden;
        pointer-events: none;
    }
    .react-datepicker__month-container {
        width: 100%;
        .react-datepicker__header {
            border: none;
            background: transparent;
            margin: 0;
            padding: 0;
            .react-datepicker__current-month {
                font-family: $helveticaBold;
                font-weight: normal;
                font-size: 20px;
                text-align: left;
                line-height: 1;
                color: $blue-med;
                text-transform: capitalize;
                padding: 0;
                margin-bottom: 32px;
            }
            .react-datepicker__day-names {
                width: 100%;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0;
                .react-datepicker__day-name {
                    width: 38px;
                    font-family: $helveticaRegular;
                    font-size: 12px;
                    line-height: 1;
                    text-align: center;
                    color: $blue-med;
                    text-transform: uppercase;
                    padding: 0;
                    margin: 0;
                    &:nth-last-child(-n+2) {
                        color: $blue-grey;
                    }
                }
            }
        }
        .react-datepicker__month {
            width: 100%;
            height: auto;
            padding: 0;
            margin: 12px 0 0 0;
            .react-datepicker__week {
                width: 100%;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .react-datepicker__day {
                    width: 38px;
                    height: 36px;
                    font-family: $helveticaRegular;
                    font-size: 16px;
                    line-height: 1;
                    text-align: center;
                    padding: 0;
                    margin: 0;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 9px;
                    &:nth-last-child(-n+2) {
                        color: $blue-grey;
                    }
                }
                .react-datepicker__day--selected {
                    color: $white !important;
                    background-color: $blue !important;
                    &:hover {
                        background-color: $blue-dark;
                    }
                }
                .react-datepicker__day--today {
                    &::after {
                        width: 4px;
                        height: 4px;
                        content: '';
                        border-radius: 50%;
                        background: $light-grey;
                        position: absolute;
                        left: calc(50% - 2px);
                        bottom: 2px;
                    }
                }
            }
        }
    }
}

.react-datepicker-popper {
    padding: 12px;
    background-color: $white;
    border-radius: 4px;
    border: 1px solid $light-grey;
    .react-datepicker__triangle {
        &::before {
            border-bottom-color: $light-grey !important;
        }
    }
    .react-datepicker__header--time {
        border: none;
        background: transparent;
        padding: 0;
        margin: 0 0 12px 0;
    }
    .react-datepicker__time-container {
        .react-datepicker-time__header {
            font-family: $helveticaBold;
            font-weight: normal;
            font-size: 18px;
            text-align: center;
            line-height: 1;
            color: $blue-med;
            text-transform: capitalize;
            padding: 0;
        }
        .react-datepicker__time {
            .react-datepicker__time-box {
                .react-datepicker__time-list {
                    .react-datepicker__time-list-item {
                        font-family: $helveticaRegular;
                        font-size: 14px;
                        line-height: 1;
                        text-align: center;
                        color: $blue-med;
                        border-radius: 4px;
                        padding-left: 0;
                        padding-right: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &.react-datepicker__time-list-item--selected {
                            color: $white !important;
                        }
                    }
                }
            }
        }
    }
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    left: 20px !important;
    top: -11px !important;
    border-bottom-color: $white !important;
}