.createVerbal {
    width: 280px;
    height: auto;

    margin: 180px auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;

    p.main {
        font-family: $helveticaBold;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: $blue-med;

        position: relative;
        display: block;

        padding: 0;
        margin-bottom: 16px;
    }

    p.copy {
        font-family: $helveticaRegular;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: $blue-grey;

        position: relative;
        display: block;

        padding: 0;
        margin-bottom: 24px;
    }
}